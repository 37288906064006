import React, { useEffect, useContext, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

import CustomAuth from "../components/custom-auth"
import { UserContext, AuthContext } from "../provider/usercontextprovider"
import { API, graphqlOperation } from "aws-amplify"
import * as buttonStyles from "../components/button.module.css"
import * as inputStyles from "../components/search-input.module.css"
import { LoadMaskContext } from "../provider/loadmaskprovider"
import * as alertStyles from "../alert.module.css"
import { Notification, NotificationType } from "../components/notification";

const InvitationPage = () => {
  const [joined, setJoined] = useState(false)
  const [userContext, setUserContext] = useContext(UserContext)
  const [authState, setAuthState] = useContext(AuthContext)
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [code, setCode] = useState("")
  const [currentTenant, setCurrentTenant] = useState({})
  const [inviteId, setInviteId] = useState(null)

  const [showWarning, setShowWarning] = useState(false)

  const getTenant = `
    query GetTenant($name: ID!) {
      getTenant(name: $name) {
        name
        prettyName
      }
    }
  `

  const acceptInvite = `
    mutation AcceptInvite($id: String!, $verificationCode: String!) {
        acceptInvitation(id: $id, verificationCode: $verificationCode) {
            inviteId
            username
        }
    }
  `
  const resendVerificationCode = `
    mutation ResendInvitationVerificationCode($id: String!) {
      resendInvitationVerificationCode(id:$id){
        inviteId,
        username
      }
    }
  `

  async function loadTenant() {
    const tenant =
      window.location.host.indexOf(".") > 0
        ? window.location.host.split(".")[0]
        : ""
    const result = await API.graphql(
      graphqlOperation(getTenant, {
        name: tenant,
      })
    )
    console.log(result)
    if (result.data.getTenant) {
      setCurrentTenant(result.data.getTenant)
    } else {
      //show some type of error page?
    }
  }

  useEffect(() => {
    if (currentTenant.name) {
      joinHandler()
    }
  }, [currentTenant.name])

  useEffect(() => {
    if (userContext.username) {
      const urlParams = new URLSearchParams(window.location.search)
      setInviteId(urlParams.get('inviteId'))
      loadTenant()
    }
  }, [userContext.username])

  useEffect(() => {
    console.log(authState)
    if (authState == "signin") {
      //   setIsLoading(true);
      setCode("")
      setCurrentTenant({ name: null })
      setJoined(false)
      setInviteId(null)
    }
  }, [authState])

  async function resendHandler() {
    setIsLoading(true)
    try {
      const result = await API.graphql(
        graphqlOperation(resendVerificationCode, {
          id: inviteId
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  async function joinHandler() {
    setIsLoading(true)
    try {
      const result = await API.graphql(
        graphqlOperation(acceptInvite, {
          id: inviteId,
          verificationCode: code,
        })
      )
      setShowWarning(false)
      setJoined(true)
    } catch (e) {
      console.log(e)
      setShowWarning(true)
      setJoined(false)
    } finally {
      setIsLoading(false)
    }
  }

  if (currentTenant.name) {
    return (
        <CustomAuth>
          <SEO title={currentTenant.prettyName} />

          <div>
            <div style={{ textAlign: "center" }}>
              <h1>{currentTenant.prettyName} Invitation Confirmation</h1>
            </div>
            { showWarning && 
              <Notification messageType={NotificationType.FAIL} hideIcon={true}>
                <p>
                Accepting this invitation failed or requires a verification code.
                Please verify the provided verification code and try again or
                contact your system administrator.
                </p>
              </Notification> 
            }

            {joined ? (
              <Notification messageType={NotificationType.SUCCESS} hideIcon={true}>
                <div>
                <h2>Invitation Confirmed!</h2>
                <p>
                  Thank you for accepting the invitation to{" "}
                  <u>{currentTenant.prettyName}</u>! Please proceed to the
                  questionnaire to complete your daily screening and begin the
                  tenant defined workflow.
                </p>
                <p style={{ textAlign: "right" }}>the DetectWise team</p>
                </div>
              </Notification>
            ) : (
              <div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <input
                    onChange={e => {
                      setCode(e.target.value)
                    }}
                    className={inputStyles.inputcss}
                    type="text"
                    placeholder="Verification Code"
                  />
                  <input
                    disabled={
                      code == null || code.length == 0 || inviteId == null
                    }
                    className={buttonStyles.button}
                    type="submit"
                    value="Join!"
                    onClick={joinHandler}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <a
                    href="#"
                    onClick={resendHandler}
                  >
                    Resend Verification Code
                  </a>
                </div>
              </div>
            )}
          </div>
        </CustomAuth>
    )
  } else {
    return (
        <CustomAuth>
          <SEO title="" />
        </CustomAuth>
    )
  }
}
export default InvitationPage
